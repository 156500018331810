import React from 'react';
import { lerp } from './math';
import { Container } from 'react-bootstrap';

interface GrowProps {
    start: number;
    finish: number;
    children: any;
    className?: string;
    show?: boolean;
    unit?: string;
}

interface GrowState {
    height: number;
    unit: string;
}

export class Grow extends React.Component<GrowProps, GrowState> {

    constructor(props: GrowProps) {
        super(props);

        this.state = { 
            height: props.start,
            unit: props.unit ? props.unit : 'vh'
        };

        if (props.show) this.grow(props.start, props.finish);
    }

    componentDidUpdate(prevProps: Readonly<GrowProps>, prevState: Readonly<GrowState>, snapshot?: any): void {

        if (prevProps.show !== this.props.show) {
            if (this.props.show) {
                this.grow(this.props.start, this.props.finish);
            } else {
                this.grow(this.props.finish, this.props.start);
            }
        }
    }

    grow(from:number, to:number) {
        let t = 0;
        let currentSize = from;
        const i = setInterval(() => {
            currentSize = lerp(currentSize, to, t += .005);
            this.setState({
                height: currentSize
            })
            if (t >= .25) {
                clearInterval(i);
            }
        }, 10)
    }

    renderChildren() {
        if (this.props.show) {
            return this.props.children;
        } else {
            return '';
        }
    }

     render() {
        return (
            <Container className = {this.props.className ? this.props.className : ''} style={{
                height: this.state.height + this.state.unit
            }}>
                { this.props.children }
            </Container>
        )
    }

}