import React, { SyntheticEvent } from 'react';
import Container from 'react-bootstrap/Container';
import { InstalledAppDisplay } from '../../backend/installedAppManager';
import Table from 'react-bootstrap/Table';

class InstalledAppRow extends React.Component<{
    installedApp: InstalledAppDisplay,
    onSelect: (event:any) => void
}> {

    render(): React.ReactNode {
        const row = this.props.installedApp;
        return (
            <tr
                id={row.installedAppId}
                onClick={this.props.onSelect}
            >
                <td>
                    {row.installedAppId}
                </td>
                <td>
                    {row.userId}
                </td>
                <td>
                    {row.numDevices}
                </td>
            </tr>
        )
    }
}

export interface InstalledAppsListProps {
    apps: InstalledAppDisplay[];
    onSelect: (installedAppId: string) => void;
}

export class InstalledAppsList extends React.Component<InstalledAppsListProps> {

    constructor(props: any) {
        super(props);

        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(event: SyntheticEvent) {
        const row = (event.target as HTMLElement).parentElement;
        if (row) {
            this.props.onSelect(row.id);
        }
    }

    render() {
        console.log(`Render with ${this.props.apps.length} records`);
        const appsRender = this.props.apps.map((app) =>
            <InstalledAppRow
                installedApp={app}
                key={app.installedAppId}
                onSelect={this.handleSelect} 
            />)
        console.log(`Found ${this.props.apps.length} apps`);
        return (
            // <div className='container mt-3 table-wrapper-scroll-y my-custom-scrollbar'>
            // <table className='table table-hover'> 
            <Container className='mt-3 table-wrapper-scroll-y my-custom-scrollbar' >
                <Table variant='hover' size='sm'>
                    <thead className='table-dark'>
                        <tr>
                            <th>Installed App Id</th>
                            <th>User ID</th>
                            <th># Apps</th>
                        </tr>
                    </thead>
                    <tbody>
                        {appsRender}
                    </tbody>
                </Table>
            </Container>
        )
    }
}