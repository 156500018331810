import React from 'react';
import { Registration } from '../../../../stwh-services';
import { RegistrationManager, RegistrationDisplay } from '../../backend/registrationManager';
import { RegistrationList } from './RegistrationList';
import { RegistrationForm } from './RegistrationForm';
import { Confirm } from '../utilities/Confirm';
import { InstalledAppManager } from '../../backend/installedAppManager';
import { Grow } from '../utilities/grow';
import { InstalledAppDetail } from '../installedApps/installedAppDetail';
import { Button } from 'react-bootstrap';


interface RegistrationPageState {
    registrations: RegistrationDisplay[];
    currentId: string;
    showDialog: boolean;
    editing: boolean;
    show: boolean;
    showInstalledApps: boolean;
    userIdToDelete: string;
    //height: string;
}

export class RegistrationPage extends React.Component<{}, RegistrationPageState> {

    regManager: RegistrationManager;
    installedAppManager: InstalledAppManager;
    mounted = false;
    //userIdToDelete: string;

    constructor(props: any) {
        super(props);
        this.regManager = new RegistrationManager();
        this.installedAppManager = new InstalledAppManager();
        //this.userIdToDelete = '';
        this.state = {
            registrations: [],
            currentId: '',
            showDialog: false,
            editing: false,
            show: false,
            showInstalledApps: false,
            userIdToDelete: '',
            //height: '25vh',
        }

        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
        this.handleShowInstalledApps = this.handleShowInstalledApps.bind(this);
    }

    async componentDidMount() {
        if (!this.mounted) {
            this.mounted = true;
            const regs = await this.regManager.getRegistrations();
            this.setState({
                registrations: regs,
                currentId: regs[0].registration.userId
            });
        }
    }

    handleUpdate(registration: Partial<Registration>) {
        this.setState({ editing: false });

        if (registration.accessToken === '') {
            // New registration
            this.regManager.createRegistration(
                registration.userId ? registration.userId : '',
                registration.emailAddress ? registration.emailAddress : '',
                registration.firstName ? registration.firstName : '',
                registration.lastName ? registration.lastName : ''
            ).then((accessToken) => {
                // this.regManager.getRegistrations().then((regs) => this.setState({
                //     registrations: regs,
                //     currentId: registration.userId ? registration.userId : ''
                // }));
                this.componentDidMount();  // requery regs
            })
        } else {
            this.regManager.updateRegistration(registration).then(() => {
                // this.regManager.getRegistrations().then((regs) => this.setState({
                //     registrations: regs
                // }));
                this.componentDidMount();
            });
        }
    }

    // grow(from:number, to:number, ) {
    //     let t = 0;
    //     let currentSize = from;
    //     const i = setInterval(() => {
    //         currentSize = lerp(currentSize, to, t += .005);
    //         this.setState({
    //             height: currentSize + 'vh'
    //         })
    //         if (t >= .25) {
    //             clearInterval(i);
    //             alert('Done');
    //         }
    //     }, 10)
    // }

    handleSelect(id: string) {
        this.setState({
            currentId: id,
            editing: true,
        })
    }

    handleNew() {
        this.setState({
            currentId: ''
        })
    }

    handleDelete(userId: string) {
        //this.userIdToDelete = userId;
        console.log('handleDelete called: ' + userId);
        this.setState({ 
            showDialog: true,
            userIdToDelete: userId, 
        });
    }

    handleConfirmDelete(confirmed: boolean) {
        this.setState({
            showDialog: false,
            editing: false,
        });
        if (confirmed) {
            this.regManager.unregister(this.state.userIdToDelete).then(() => {
                // this.regManager.getRegistrations().then((regs) => {
                //     this.setState({
                //         registrations: regs,
                //         currentId: regs[0].userId
                //     });
                // });
                // this.componentDidMount();
                this.regManager.getRegistrations().then((regs) => {
                    this.setState({
                        registrations: regs,
                        currentId: regs[0].registration.userId,
                        userIdToDelete: '',
                    })
                    console.log('Deleted user: ' + this.state.userIdToDelete);
                });
      
            })
        }
    }

    displayInstalledApps(userId: string) {
        const reg = this.state.registrations.find((r) => r.registration.userId === userId);
        if (reg) {
            return (
                <InstalledAppDetail installedApp={reg.installedApp} onClose={() => this.setState({showInstalledApps: false})} />
             )
        } else {
            return <p> OOPS!</p>
        }

    }

    renderForm() {
        let reg: Registration | undefined;
        if (this.state.currentId === '') {
            reg = {
                userId: '',
                accessToken: '',
                created: new Date(),
                modified: new Date(),
                emailAddress: '',
                firstName: '',
                lastName: '',
                payPalSubId: '',
            }
        } else {
            reg = this.state.registrations.find((registration) => registration.registration.userId === this.state.currentId)?.registration;

            if (!reg) {
                reg = this.state.registrations[0].registration;
            }
        }

        if (this.mounted && this.state.editing) {
                return <RegistrationForm
                registration={reg}
                onUpdate={this.handleUpdate}
                onCancel={() => this.setState({ editing: false })}
            />
        }
    }

    handleShowInstalledApps(userId: string) {
        console.log('handle show apps called: ' + userId);
        this.setState({currentId: userId, showInstalledApps: true});
    }

    renderDetails() {
        console.log('render detailes called');
        if (this.state.editing) return this.renderForm();
        if (this.state.showInstalledApps) return this.displayInstalledApps(this.state.currentId);
        return '';
    }

    async downloadEmails() {
        const emails = await this.regManager.getEmailAddresses();
        const url = window.URL.createObjectURL(emails);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'emails.txt';
        a.click();
    }

    render() {
        return (
            <div>
                <Confirm
                    header='Confirm Deletion'
                    body={`Are you sure you want to delete ${this.state.userIdToDelete}?`}
                    show={this.state.showDialog}
                    onClose={this.handleConfirmDelete}
                />
                <div className='center w-100'>
                        {this.state.registrations.length} Total Accounts&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button className='right' onClick={this.downloadEmails.bind(this)}>
                        Emails
                    </Button>
                </div>
                <Grow className='table-wrapper-scroll-y my-custom-scrollbar' start={90} finish={25} show={this.state.editing || this.state.showInstalledApps}>
                    <RegistrationList
                        registrations={this.state.registrations}
                        onSelect={this.handleSelect}
                        onNew={this.handleNew}
                        onDelete={this.handleDelete}
                        editing={this.state.editing}
                        onSelectInstalledApps={this.handleShowInstalledApps}
                    />
                </Grow>
                    <div>
                        {this.renderDetails()}
                    </div>
            </div>
        )
    }
}
