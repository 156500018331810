
import { EventType, STWHEvents, EventMetrics} from '../../../stwh-services'
import { getAxiosInstance } from './axiosFactory';

export class EventManager {

    events: STWHEvents | undefined;

    async getEvents(start: Date, end?: Date, eventType?: EventType) {
        let queryParameters = 'start=' + String(start.getTime());
        if (end) {
            queryParameters += '&end=' + String(end.getTime());
        }

        if (eventType) {
            queryParameters += '&eventtype=' + eventType;
        }
        const events = (await getAxiosInstance().get('/events?' + queryParameters)).data;
        this.events = events;
        return events;
    }

    async getMetrics(granularity: string, start: Date, end?: Date, appId?: string, eventType?: EventType): Promise<EventMetrics | undefined> {
        let queryParameters = `granularity=${granularity}&start=${String(start.getTime())}`;
        if (end) queryParameters += '&end=' + String(end.getTime());
        if (appId) queryParameters += '&appid=' + appId;
        if (eventType) queryParameters += '&eventtype=' + eventType;

        try {
            const metrics = (await getAxiosInstance().get('/metrics?' + queryParameters)).data;
            return metrics as EventMetrics;
        } catch(error) {
            alert('Got error from service: ' + error);
            return undefined;
        }
    }
}