import React, { SyntheticEvent } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

interface ConfirmProps {
    show: boolean;
    header: string;
    body: string;
    onClose: (confirmed: boolean) => void;
}

export class Confirm extends React.Component<ConfirmProps,{}> {

    constructor(props: any) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
        this.handleHide = this.handleHide.bind(this);
    }

    handleClose(event: SyntheticEvent) {
        if ((event.target as HTMLElement).id === 'confirm') {
            this.props.onClose(true);
        } else {
            this.props.onClose(false);
        }
    }

    handleHide() {
        this.props.onClose(false);
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.handleHide}>
                <Modal.Header closeButton>
                    <h3>{this.props.header}</h3>
                </Modal.Header>
                <Modal.Body>
                    {this.props.body}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='primary' id='confirm' onClick={this.handleClose}>Confirm</Button>
                    <Button variant='warning' id='cancel' onClick={this.handleClose}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}