import React from 'react';
import './App.css';
import { EventChart } from './components/installedApps/eventChart';
import { MainPage } from './components/MainPage';


function App() {
  return (
      <MainPage />
  );
}

export default App;
