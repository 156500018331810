import React, { SyntheticEvent } from 'react';
//import { Registration } from '../../../../stwh-services/build/types';
import { RegistrationDisplay } from '../../backend/registrationManager';
import { BsTrash } from 'react-icons/bs';
import { Table, Button } from 'react-bootstrap';
import appIndicator from '../../assets/app-indicator.svg';

class RegistrationRow extends React.Component<{
    registration: RegistrationDisplay, 
    onSelect: (event:any) => void,
    onDelete: (event:any) => void,
    onSelectInstalledApps: (event:any) => void,
}> {

    renderAppIcon(reg: RegistrationDisplay) {
        if (reg.installedApp) {
            return <Button id={reg.registration.userId} onClick={this.props.onSelectInstalledApps}><img id={reg.registration.userId} src={appIndicator} alt='Installed app present' /></Button>
        } else {
            return ''
        }
    }

    render(): React.ReactNode {
        const row = this.props.registration;
        return (
            <tr id={row.registration.userId}>
                <td>
                    <Button variant='link' onClick={this.props.onSelect}>{row.registration.userId}</Button>
                </td>
                <td>
                    {row.registration.emailAddress}
                </td>
                <td>
                    {row.registration.payPalSubId}
                </td>
                <td>
                    {this.renderAppIcon(row)}
                </td>
{/*             <td>
                    {row.registration.firstName}
                </td>
                <td>
                    {row.registration.lastName}
                </td>
*/}
                <td>
                    <BsTrash onClick={this.props.onDelete} id={row.registration.userId}/>
                </td>
            </tr>
        )
    }
}

export class RegistrationList extends React.Component<{
    registrations: RegistrationDisplay[];
    onSelect: (userId: string) => void;
    onNew: () => void;
    onDelete: (userId: string) => void;
    onSelectInstalledApps: (userId: string) => void;
    editing: boolean;
}, {}> {
    mounted = false;

    constructor(props: {
        registrations: RegistrationDisplay[],
        onSelect: (userId: string) => void,
        onNew: () => void
    }) {
        super(props as any);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleNewRegistration = this.handleNewRegistration.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleSelectInstalledApps = this.handleSelectInstalledApps.bind(this);
     }

    handleSelect(event: SyntheticEvent) {
        const row = (event.target as HTMLElement).parentElement?.parentElement;
        if (row) {
            this.props.onSelect(row.id);
        }
    }

    handleNewRegistration(event: SyntheticEvent) {
        this.props.onNew();
    }

    handleDelete(event: SyntheticEvent) {
        this.props.onDelete((event.target as HTMLElement).id);
    }

    handleSelectInstalledApps(event: SyntheticEvent) {
        this.props.onSelectInstalledApps((event.target as HTMLElement).id as string);
    }

    render() {
        const regsRender = this.props.registrations.map((reg) => 
            <RegistrationRow
                registration={reg} 
                key={reg.registration.userId} 
                onSelect={this.handleSelect} 
                onDelete={this.handleDelete} 
                onSelectInstalledApps={this.handleSelectInstalledApps}
            />)

        // const className = this.props.editing ? 'mt-3 my-custom-scrollbar-small' : 'mt-3 my-custom-scrollbar';
        return (
            // <div className='container mt-3 table-wrapper-scroll-y my-custom-scrollbar'>
            // <table className='table table-hover'> 
            <div>
                <Table variant='hover' size='sm'>
                <thead className='table-dark'>
                    <tr>
                        <th>User ID</th>
                        <th>Email Address</th>
                        <th>Paypal Sub ID</th>
                        <th><img src={appIndicator} className='white' alt='yeah' /></th>
                        { /* 
                        <th>Last Name</th>
                        */ }
                        <th>
                            <button onClick={this.handleNewRegistration} className='btn btn-dark special-button'>+</button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {regsRender}
                </tbody>
            </Table>
            </div>
        )
    }
}