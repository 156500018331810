import React from 'react';
import Container from 'react-bootstrap/Container';
import { Session } from '../../../../stwh-services/build/types';
import { InstalledAppDisplay, InstalledAppManager } from '../../backend/installedAppManager';
import { InstalledAppDetail } from './installedAppDetail';
import { InstalledAppsList } from './installedApps.List';
import { SessionDetails } from './sessionDetails';

interface InstalledAppsPageState {
    apps: InstalledAppDisplay[];
    selectedApp: string;
    selectedAppSession: Session | undefined;
}
export class InstalledAppsPage extends React.Component<{}, InstalledAppsPageState> {
    installedAppsManager = new InstalledAppManager();
    
    constructor(props: any) {
        super(props);
        this.state = {
            apps: [],
            selectedApp: '',
            selectedAppSession: undefined
        }

        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount(): void {
        this.installedAppsManager.getInstalledApps().then((apps) => {
            this.setState({
                apps: apps
            })
        })
    }

    handleSelect(installedAppId: string) {
        this.setState({
            selectedApp: installedAppId,
            selectedAppSession: undefined,
        });

        this.installedAppsManager.getActiveSession(installedAppId).then((session) => {
            if (session) {
                this.setState({
                    selectedAppSession: session
                })
            }
        })
        
    }

    renderDetail() {
        
        if (this.state.selectedApp !== '') {
            const app = this.state.apps.find((app) => app.installedAppId === this.state.selectedApp);

            if (app) {
                return (
                    <InstalledAppDetail installedApp={app} onClose={() => console.log('Close')} />
                )
            } else {
                return '';
            }
        }
    }

    renderSessionDetail() {
        if (this.state.selectedAppSession) {
            return (
                <SessionDetails session={this.state.selectedAppSession}/>
            ) 
        } else {
                return '';
        }
    }

    render() {
        return (
            <Container>
                <InstalledAppsList apps={this.state.apps} onSelect={this.handleSelect}/>
                { this.renderDetail() }
                { this.renderSessionDetail() }
            </Container>
        )
    }
}