import { AxiosInstance} from 'axios';
import { getAxiosInstance, setToken } from './axiosFactory';

export class LoginManager {
    axios: AxiosInstance;

    constructor() {
        this.axios = getAxiosInstance();
    }

    async login(userId: string, password: string): Promise<string | undefined> {

        try {
            const resp = await this.axios.post('/login', {
                userId: userId,
                password: password
            })
            setToken(resp.data.token);
            return resp.data.token;
        } catch (error) {
            return undefined;
        }
    }
}
