import React from 'react';
import { Container, Form, FormControl, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { XYPlot, HorizontalGridLines, LineSeries, XAxis, YAxis } from 'react-vis'
import { EventType } from '../../../../stwh-services';
import { EventManager } from '../../backend/eventManager';
//import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';


interface EventChartProps {
    granularity: string;
    startDate: Date;
    endDate?: Date;
    appId?: string;
    eventType?: EventType;
}

interface DataPoint { x: number; y: number };

interface EventChartState {
    width: number;
    data: DataPoint[];
}

export class EventChart extends React.Component<EventChartProps, EventChartState> {
    mounted = false;
    eventManager = new EventManager();

    constructor(props: any) {
        super(props);

        this.state = {
            width: window.innerWidth,
            data: []
        }

    }

    // shouldComponentUpdate(nextProps: Readonly<EventChartProps>, nextState: Readonly<{ data: dataPoint[]; }>, nextContext: any): boolean {
    //     let refresh = false;

    //     if (this.props.granularity !== nextProps.granularity) refresh = true;

    //     return refresh;
    // }

    componentDidUpdate(prevProps: Readonly<EventChartProps>, prevState: Readonly<{ data: DataPoint[]; }>, snapshot?: any): void {
        for(const key in prevProps) {
            if ((prevProps as any)[key] !== (this.props as any)[key]) {
                this.refreshData();
                return;
            }
        }
    }

    componentDidMount(): void {
        if (!this.mounted) {
            this.mounted = true;
            this.refreshData();
            window.addEventListener('resize', () => this.setState({width: window.innerWidth}));
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize',() => this.setState({width: window.innerWidth}) );
    }

    refreshData() {
        if (this.mounted) {
            this.eventManager.getMetrics(this.props.granularity, this.props.startDate, this.props.endDate, this.props.appId, this.props.eventType).then((metrics) => {
                const data: DataPoint[] = [];
                let i = 1;
                metrics?.timeSeries.forEach(ts => {
                    data.push({
                        x: i++,
                        y: ts.count,
                    })
                });
                this.setState({ data: data })
            })
        }
    }

    render() {
        //const windowSize = useRef([window.innerWidth, window.innerHeight]);
        return (
            <div>
                <XYPlot className={'chart'}
                    height={150}
                    width={this.state.width - (this.state.width * .25)}
                >
                    <LineSeries data={this.state.data} animation/>
                    <HorizontalGridLines />
                    <XAxis width={window.innerWidth} />
                    <YAxis width={50} />
                </XYPlot>
            </div>
        )
    }
}


interface EventChartWithControlsState {
    granularity: string;
    start: string;
    end: string;
    startDate: Date;
    endDate: Date;
}

interface EventChartWithControlsProps {
    appId?: string;
}

export class EventChartWithControls extends React.Component<EventChartWithControlsProps, EventChartWithControlsState> {

    constructor(props: any) {
        super(props);

        // const startDate = new Date(Date.now() - (7 * 1000 * 60 * 60 * 24));
        const startDate = new Date(Date.now() - (24 * 60 * 60 * 1000));
        const endDate = new Date(Date.now());
        this.state = {
            // granularity: 'D',
            granularity: 'H',
            startDate: startDate,
            endDate: endDate,
            start: startDate.toLocaleString(),
            end: endDate.toLocaleString(),
        }

        this.handleFieldChange = this.handleFieldChange.bind(this);
    }

    handleFieldChange(event: any) {
        const targetName = event.target.name;
        const value = event.target.value;

        this.setState({ [targetName]: value } as Pick<EventChartWithControlsState, keyof EventChartWithControlsState>);
    }

    render() {
        return (
            <Container>
                <Form className='bg-light my-3'>
                    <Row></Row>
                    <FormGroup>
                        <FormLabel>Granularity:</FormLabel>
                        <Form.Select name='granularity' onChange={this.handleFieldChange} value={this.state.granularity}>
                            <option value="M">Minute</option>
                            <option value="H">Hourly</option>
                            <option value="D">Daily</option>
                            <option value="W">Weekly</option>
                        </Form.Select>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Start Date / Time</FormLabel>
                        <FormControl onBlur={() => this.setState({startDate: new Date(this.state.start)})} name='start' type='text' onChange={this.handleFieldChange} value={this.state.start} />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>End Date / Time</FormLabel>
                        <FormControl onBlur={() => this.setState({endDate: new Date(this.state.end)})} name='end' type='text' onChange={this.handleFieldChange} value={this.state.end} />
                    </FormGroup>
                </Form>
                <h5>All events</h5>
                <EventChart appId={this.props.appId} granularity={this.state.granularity} startDate={this.state.startDate} endDate={this.state.endDate} />
                <h5>Client Events</h5>
                <EventChart appId={this.props.appId} granularity={this.state.granularity} startDate={this.state.startDate} endDate={this.state.endDate} eventType='CLIENT_EVENT' />
                <h5>SmartThings Events</h5>
                <EventChart appId={this.props.appId} granularity={this.state.granularity} startDate={this.state.startDate} endDate={this.state.endDate} eventType='ST_EVENT' />
            </Container>
        )
    }
}