import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import { InstalledAppsPage } from './installedApps/installedAppsPage';
import { RegistrationPage } from './registration/RegistrationPage';
import { LoginForm } from './login/loginForm';
import { Button } from 'react-bootstrap';
import { EventChartWithControls } from './installedApps/eventChart';

export class MainPage extends React.Component<{}, {currentPage: string|null, loggedIn: boolean}> {

    constructor(props:any) {
        super(props);

        this.state = {
            currentPage: 'metrics',
            loggedIn: false
        }

        this.handleLogin = this.handleLogin.bind(this);
    }

    handleLogin(token: string) {
        this.setState({loggedIn: true});
    }

    renderPage() {
        if (!this.state.loggedIn) {
            return <LoginForm onLogin={this.handleLogin} />
        }

        switch(this.state.currentPage) {
            case 'metrics':
                return <EventChartWithControls />
            case 'registration':
                return <RegistrationPage />
            default:
                return <InstalledAppsPage />
        }
    }

    render(): React.ReactNode {
        return (
            <Container >
                <div className='banner'>
                    <h1>STWH Admin</h1>
                </div>
                <Nav variant="tabs" 
                    defaultActiveKey="/home" 
                    className='justify-content-center' 
                    activeKey="1" 
                    onSelect={(eventKey) => this.setState({ currentPage: eventKey})}
                >
                    <Nav.Item>
                        <Nav.Link eventKey="metrics">Metrics</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="registration">Registration</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                        <Nav.Link eventKey="installed-apps">Installed Apps</Nav.Link>
                    </Nav.Item> */}
                    <Button hidden={!this.state.loggedIn} onClick={() => this.setState({loggedIn: false})}>Logout</Button>
                </Nav>
                
                {this.renderPage()}
            </Container>
        )
    }
}