import { AxiosInstance } from 'axios';
import { InstalledApp, Registration } from '../../../stwh-services/build/types'
import { getAxiosInstance } from './axiosFactory';
import { Session } from '../../../stwh-services/build/types';

export interface InstalledAppDisplay {
    installedAppId: string;
    userId: string;
    numDevices: number;
    installed: Date;
    lastActivity: Date;
}
export class InstalledAppManager {
    axios: AxiosInstance
    constructor() {
        this.axios = getAxiosInstance();
    }

    async getInstalledApps(): Promise<InstalledAppDisplay[]> {
        const installedApps = ((await this.axios.get('/getallinstalledapps')).data) as InstalledApp[];
        let returnedApps: InstalledAppDisplay[] = [];

        for await (const app of installedApps) {
            try {
                const registration = ((await this.axios.get('/getregistration?accesstoken=' + app.accessToken)).data) as Registration;
                const a: InstalledAppDisplay = {
                    installedAppId: app.installedAppId,
                    userId: registration.userId,
                    numDevices: app.numDevices,
                    installed: new Date(app.installed as unknown as string),
                    lastActivity: new Date(app.lastActivity as unknown as string),
                }
                returnedApps.push(a);
            } catch (error) {
                // Do nothing
            }
        }

        return returnedApps;
    }

    async getInstalledAppsForAccount(accessToken: string): Promise<InstalledAppDisplay> {
        const app = (await this.axios.get('/getinstalledapp?accesstoken=' + accessToken)).data;
        return app;
    }

    async getActiveSession(installedAppId: string): Promise<Session | undefined> {
        const sessions: any[] = (await this.axios.get('/getsessionsbyinstalledapp?installedappid=' + installedAppId)).data;

        const activeSession = sessions.find((session) => session.active);

        if (activeSession) {
            return {
                installedAppId: activeSession.installedAppId,
                active: true,
                lastActivity: new Date(activeSession.lastActivity),
                numberOfEvents: activeSession.numberOfEvents,
                requestInProgress: activeSession.requestInProgress,
                sessionId: activeSession.sessionId,
                sessionStarted: new Date(activeSession.sessionStarted),
            }
        }
    }
}