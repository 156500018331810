import axios, { AxiosInstance} from 'axios';
import * as settings from './settings';

var axiosInstance: AxiosInstance|undefined = undefined;

export function getAxiosInstance(): AxiosInstance {
    if (axiosInstance) {
        return axiosInstance
    } else {
        axiosInstance = axios.create({
            baseURL: settings.stwhServicesURL,
        });
        return axiosInstance;
    }
}

export function setToken(token: string) {
    if (axiosInstance) {
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer: ${token}`;
    } 
}