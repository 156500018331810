import React, { FormEvent, SyntheticEvent } from 'react';
import { Container, Form, FormLabel} from 'react-bootstrap';
import { LoginManager } from '../../backend/loginManager';

interface LoginFormState{
    userId: string;
    password: string;
    hideAlert: boolean;
}
export class LoginForm extends React.Component<{onLogin: (token: string) => void}, LoginFormState | any> {

    loginManager = new LoginManager();

    constructor(props:any) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            userId: '',
            password: '',
            hideAlert: true
        }
    }

    handleChange(event: FormEvent) {
        const target: HTMLInputElement = event.target as HTMLInputElement;
        const value = target.value;
        const name = target.name;

        this.setState({ 
            [name]: value,
            hideAlert: true
        });
    }

    async handleSubmit(event: SyntheticEvent) {
        event.preventDefault();
        this.loginManager.login(this.state.userId, this.state.password).then((token) => {
            if (!token) {
                this.setState({
                    password: '',
                    hideAlert: false
                })
            } else {
                this.props.onLogin(token);
            }
        });
    }

    render() {
        return (
            <Container className='bg-light'>
                <Form onSubmit={this.handleSubmit}>
                    <FormLabel htmlFor='userId'>User ID:</FormLabel>
                    <input 
                        type="text" 
                        className='form-control' 
                        name="userId" id="userId" 
                        value={this.state.userId}
                        onChange={this.handleChange} />

                    <FormLabel htmlFor='password'>Password:</FormLabel>
                    <input type="password" 
                        className='form-control'  
                        name="password" id="password" 
                        value={this.state.password}
                        onChange={this.handleChange}
                    />

                    <input type="submit" className='btn btn-primary my-3' value={"Login"} onChange={(event) => this.setState({userId: event.target.value})} />
                </Form>
                <div hidden={this.state.hideAlert} className='mt-3 p-2 center bg-primary text-white rounded'>
                    <h5>User ID or Password Not Found</h5>
                </div>
            </Container>
        )
    }
}