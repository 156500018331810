import React from 'react';
import { Session } from '../../../../stwh-services';
import { Container, Row, Col } from 'react-bootstrap';

interface SessionDetailsProps {
    session: Session
}
export class SessionDetails extends React.Component<SessionDetailsProps, {}> {

    render() {
        const sessionHours = (new Date().getTime() - this.props.session.sessionStarted.getTime()) / 3600000;
        const eventsPerHour = this.props.session.numberOfEvents / sessionHours;
        const eventsPerMinute = eventsPerHour / 60;
        return (
            <Container className='bg-light'>
            <Row>
                <Col>
                    <label className='detail-label'>Session Id:</label> {this.props.session.sessionId}
                </Col>
                <Col>
                    <label className='detail-label'>Number of Events:</label> {this.props.session.numberOfEvents.toLocaleString()}
                </Col>
            </Row>
            <Row>
                <Col>
                    <label className='detail-label'>Active?</label> {this.props.session.active ? 'Yes' : 'No'}
                </Col>
                <Col>
                    <label className='detail-label'>Request in Progress?</label> {this.props.session.requestInProgress ? 'Yes' : 'No'}
                </Col>
            </Row>
            <Row>
                <Col>
                    <label className='detail-label'>Session Started:</label> {this.props.session.sessionStarted.toLocaleString()}
                </Col>
                <Col>
                    <label className='detail-label'>Session Hours:</label> {sessionHours.toLocaleString()}
                </Col>
            </Row>
            <Row>
                <Col>
                    <label className='detail-label'>Events per Hour:</label> {eventsPerHour.toLocaleString()}
                </Col>
                <Col>
                    <label className='detail-label'>Events per Minute:</label> {eventsPerMinute.toLocaleString()}
                </Col>
            </Row>



        </Container>

        )
    }
}