import React, { FormEvent } from 'react';
import { Registration } from '../../../../stwh-services';
import { Container, Form, FormGroup, Row, Col} from 'react-bootstrap';


interface RegistrationFormProps {
    registration: Registration;
    onUpdate: (registration: Partial<Registration>) => void;
    onCancel: () => void;
}
export class RegistrationForm extends React.Component<RegistrationFormProps, Partial<Registration>> {

    constructor(props: { registration: Registration }) {
        super(props as any);

        // this.state = {
        //     userId: props.registration.userId,
        //     firstName: props.registration.firstName,
        //     lastName: props.registration.lastName,
        //     emailAddress: props.registration.emailAddress,
        // };
        this.state = props.registration;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    handleSubmit(event: FormEvent) {
        event.preventDefault();
        this.props.onUpdate(this.state);
    }

    handleCancel(event: FormEvent) {
        this.props.onCancel();
    }

    handleChange(event: FormEvent) {
        const target: HTMLInputElement = event.target as HTMLInputElement;
        const value = target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    componentDidUpdate(prevProps: Readonly<{ registration: Registration; onUpdate: (registration: Partial<Registration>) => void; }>, prevState: Readonly<Partial<Registration>>, snapshot?: any): void {
        if (this.props.registration.userId !== prevProps.registration.userId) {
            this.setState(this.props.registration);
        }
    }

    render() {
        const newItem = this.props.registration.userId === '';

        return (
            <Container className='bg-light'>
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <label htmlFor='userId'>User ID</label>
                        <input type='text' readOnly={!newItem} disabled={!newItem} className='form-control' id='userId' name='userId' onChange={this.handleChange} value={this.state.userId} />
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor='accessToken'>Access Token</label>
                        <input type='text' readOnly disabled className='form-control' id='accessToken' name='accessToken' value={this.state.accessToken} />
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor='payPalSubId'>Paypal Subscription ID</label>
                        <input type='text' readOnly disabled className='form-control' id='payPalSubId' name='payPalSubId' value={this.state.payPalSubId} />
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor='firstName'>First name</label>
                        <input type='text' className='form-control' onChange={this.handleChange} id='firstName' name='firstName' value={this.state.firstName} />
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor='lastName'>Last name</label>
                        <input type='text' className='form-control' onChange={this.handleChange} id='lastName' name='lastName' value={this.state.lastName} />
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor='emailAddress'>Email Address</label>
                        <input type='email' className='form-control' onChange={this.handleChange} id='emailAddress' name='emailAddress' value={this.state.emailAddress} />
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor='created'>Created</label>
                        <input type='text' readOnly disabled className='form-control' id='created' name='created' value={this.state.created?.toLocaleString()} />
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor='modified'>Modified</label>
                        <input type='text' readOnly disabled className='form-control' id='modified' name='modified' value={this.state.modified?.toLocaleString()} />
                    </FormGroup>
                    <Row className='py-2'>
                        <Col sm={2}></Col>
                        <Col sm={8} className='text-center'>
                            <div>
                                <input className='btn btn-primary mx-2' type='submit' value='Save' />
                                <input className='btn btn-warning cancel' type='button' value='Cancel' onClick={this.handleCancel} />
                            </div>
                        </Col>
                        <Col sm={2}></Col>
                    </Row>

                </Form>
            </Container>
        )
    }

}