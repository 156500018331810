import { AxiosError, AxiosInstance } from 'axios';
import { Registration } from '../../../stwh-services/build/types'
import { getAxiosInstance } from './axiosFactory';
import { InstalledAppDisplay } from './installedAppManager';

export interface RegistrationDisplay {
    registration: Registration;
    installedApp: InstalledAppDisplay;
}
export class RegistrationManager {
    axios: AxiosInstance;

    constructor() {
        this.axios = getAxiosInstance();
    }

    async createRegistration(userId: string, emailAddress: string, firstName: string, lastName: string): Promise<string> {
        const resp = await this.axios.post('/register', {
            userId: userId,
            firstName: firstName,
            lastName: lastName,
            emailAddress: emailAddress
        });

        return resp.data.accessToken as string;
    }

    async getRegistrations(): Promise<RegistrationDisplay[]> {
        const returnedRegistrations: RegistrationDisplay[] = [];
        try {
            const resp = await this.axios.get('/getregistrations');
            const regs = resp.data as unknown as Registration[];

            for await (const reg of regs) {
                let installedApp = undefined;
                if (reg.accessToken !== '') {
                    try {
                        installedApp = (await this.axios.get(`/getinstalledapp?accesstoken=${reg.accessToken}`)).data;
                        if (installedApp) {
                            installedApp.userId = reg.userId;
                        }
                    } catch (error) {
                        if ((error as AxiosError).response?.status !== 404) {  // Not found
                            alert('Error from server: ' + (error as AxiosError).code);
                        }
                    }
                }
                returnedRegistrations.push({ registration: reg, installedApp: installedApp })
            }

            return returnedRegistrations;

        } catch (error) {
            alert('Communication error: ' + String(error));
            return [];
        }
    }

    async updateRegistration(registration: Partial<Registration>) {
        await this.axios.post('/updateRegistration?userid=' + registration.userId, registration);
    }

    async unregister(userId: string) {
        await this.axios.delete('/unregister?userid=' + userId);
    }

    async getEmailAddresses(): Promise<Blob> {
        const resp = await this.axios.get('/activeemailaddresses');
        const emailAddresses = resp.data as string[];
        const returnString = emailAddresses.join('\n');
        return new Blob([returnString], {type: 'text/plain;charset=utf-8'});
    }

}