
// const STWH_SERVICES_URL = 'http://localhost:8080';
let STWH_SERVICES_URL:string;

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    STWH_SERVICES_URL = 'http://192.168.1.175:8080';
} else {
    STWH_SERVICES_URL = 'https://stwh.kleinstudios.net/services';
}

//const stwhServicesURL = process.env.STWH_SERVICES_URL ? process.env.STWH_SERVICES_URL : STWH_SERVICES_URL;

export const stwhServicesURL = process.env.STWH_SERVICES_URL ? process.env.STWH_SERVICES_URL : STWH_SERVICES_URL;
export {}
