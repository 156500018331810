import React from 'react';
import { Container, Row, Col, CloseButton } from 'react-bootstrap';
import { InstalledAppDisplay } from '../../backend/installedAppManager';
import { EventChartWithControls } from './eventChart';


interface InstalledAppDetailProps {
    installedApp: InstalledAppDisplay;
    onClose: () => void;
}
export class InstalledAppDetail extends React.Component<InstalledAppDetailProps, {}> {

    render() {

        return (
            <Container className='bg-light my-3'>
                <Row>
                    <Col>
                        <label className='detail-label'>User ID:</label> {this.props.installedApp.userId}
                    </Col>
                    <Col>
                        <label className='detail-label'>Devices:</label> {this.props.installedApp.numDevices}
                    </Col>
                    <Col xs={1} >
                        <CloseButton onClick={() => this.props.onClose()}  />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label className='detail-label'>Installed On:</label> {this.props.installedApp.installed.toLocaleString()}
                    </Col>
                    <Col>
                        <label className='detail-label'>Last Activity:</label> {this.props.installedApp.lastActivity.toLocaleString()}
                    </Col>
                    <Col xs={1}></Col>
                </Row>
                <EventChartWithControls appId={this.props.installedApp.installedAppId} />
            </Container>
        )
    }
}